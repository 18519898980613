@import "../styles/easings.scss";
@import "../styles/mixins.scss";

.main {
	@include basic-reset-button;

	color: var(--color-button-text);
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	border: 1px solid currentColor;
	padding: 5px 10px 5px;
	border-radius: 4px;
	display: flex;
	gap: 8px;
	align-items: center;

	&:disabled {
		opacity: var(--opacity-button-disabled);
		pointer-events: none;
	}
}

.icon {
	width: 24px;
	height: 24px;
}

.text {
	padding-top: 2px;
	text-transform: uppercase;
}
