@import "../styles/easings.scss";
@import "../styles/zindexes.scss";

$fade-in-time: 0.4s;
$padding-vertical: 14px;
$padding-horizontal: 14px;
$padding-horizontal-out: 10px;

.main {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $z-index-sidemenu;
	pointer-events: none;
	overflow: hidden;

	&.visible {
		pointer-events: all;
	}
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-menu-overlay);
	opacity: 0;
	transition: all $fade-in-time $easing-out-quad;

	.main.visible & {
		opacity: var(--opacity-menu-overlay);
	}
}

.content {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	bottom: 0;
	left: 0;
	width: 80%;
	background-color: var(--color-menu-background);
	transform: translateX(calc(-100%));
	transition: transform $fade-in-time $easing-out-quart;
	padding: $padding-vertical $padding-horizontal $padding-vertical calc($padding-horizontal + $padding-horizontal-out);
	color: var(--color-menu-foreground-text);
	gap: calc($padding-vertical * 4);
	pointer-events: none;

	.main.visible & {
		transform: translateX(0);
		pointer-events: initial;
	}
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	& .logoArea {
		display: flex;
		align-content: center;
		height: 15px;

		& > * {
			height: 100%;
		}
	}

	& .closeButton {
		color: var(--color-menu-foreground-close);
	}
}

.menu {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 24px;

	& .menuItem {
		display: flex;
		flex-direction: row;
		cursor: pointer;
		font-size: 20px;
		gap: 8px;
		align-items: center;
		margin-left: -2px;
		user-select: none;
	}
}

.footer {
	& .title {
		font-weight: bold;
		margin: 0 0 0.8em 0;
		font-size: 16px;
	}

	& .logos {
		display: flex;
		flex-direction: row;
		gap: 10px;

		& > a {
			color: var(--color-menu-foreground-text);
		}
	}
}
