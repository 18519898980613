@import "../styles/easings.scss";
@import "../styles/zindexes.scss";

$time-show: 0.4s;
$time-hide: 0.35s;
$padding-outside: 0px;
$padding-vertical: 18px;
$padding-horizontal: 18px;
$header-height: 60px;

.main {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $z-index-dialog;
	pointer-events: none;
	overflow: hidden;

	&.visibilityShown {
		pointer-events: all;
	}
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-dialog-overlay);
	opacity: 0;

	.main.visibilityShowing &,
	.main.visibilityShown & {
		animation: dialog-show-overlay $time-show $easing-out-quad;
		animation-fill-mode: both;
	}

	.main.visibilityHiding & {
		animation: dialog-hide-overlay $time-hide $easing-out-quad;
		animation-fill-mode: both;
	}
}

.content {
	position: absolute;
	display: flex;
	flex-direction: column;
	bottom: $padding-outside;
	left: $padding-outside;
	right: $padding-outside;
	top: $padding-outside;
	background-color: var(--color-dialog-background);
	color: var(--color-dialog-foreground-text);
	transform: translateY(calc(100% + $padding-outside));

	.main.visibilityShowing &,
	.main.visibilityShown & {
		animation: dialog-show-content $time-show $easing-out-quart;
		animation-fill-mode: both;
	}

	.main.visibilityHiding & {
		animation: dialog-hide-content $time-hide $easing-out-quart;
		animation-fill-mode: both;
	}
}

.header {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	background-color: var(--color-dialog-background);
	padding: $padding-vertical 0 0 $padding-horizontal;
	height: $header-height;
	z-index: $z-index-dialog-header;
	width: calc(100% - $padding-horizontal);

	& .closeButton {
		color: var(--color-dialog-foreground-close);
	}

	& .title {
		font-weight: bold;
		font-size: 24px;
		text-transform: uppercase;
	}
}

.body {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $header-height $padding-horizontal $padding-vertical;
	overflow-y: auto;
	scrollbar-gutter: stable;

	* {
		line-height: 175%;
	}

	p {
		font-size: 18px;
		margin-block: 0.8em;
	}
}

@keyframes dialog-show-overlay {
	from {
		opacity: 0;
	}
	to {
		opacity: var(--opacity-dialog-overlay);
	}
}

@keyframes dialog-show-content {
	from {
		transform: translateY(calc(100% + $padding-outside));
	}
	to {
		transform: translateY(0);
	}
}

@keyframes dialog-hide-overlay {
	from {
		opacity: var(--opacity-dialog-overlay);
	}
	to {
		opacity: 0;
	}
}

@keyframes dialog-hide-content {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(calc(100% + $padding-outside));
	}
}
