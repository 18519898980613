@import "../styles/easings.scss";
@import "../styles/mixins.scss";
@import "../styles/sizes.scss";

$key-width: calc((100% - (9 * $keyboard-gap-horizontal)) / 10); // Desired: 30px
$key-hit-slop-horizontal: calc($keyboard-gap-horizontal / 2);
$key-hit-slop-vertical: calc($keyboard-gap-vertical / 2);

.main {
	@include basic-reset-button;

	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: $key-width;
	height: 50px;
	color: var(--color-keyboard-key-text);
	background-color: var(--color-keyboard-key-background);
	border-radius: 3px;
	transition:
		transform 0.5s $easing-out-circ,
		border-radius 0.5s $easing-out-circ,
		opacity 0.2s linear;
	font-size: 17px;
	font-weight: bold;
	text-align: center;
	padding: 2px 0 0 0;

	&:disabled {
		opacity: var(--opacity-keyboard-key-disabled);
		pointer-events: none;

		& .hitArea {
			cursor: auto;
		}
	}

	&.large {
		width: calc($key-width * 1.5);
	}

	&.veryVeryLarge {
		width: calc($key-width * 2.6);
		transform: scale(1.2);
	}

	&.incompatible {
		transform: scale(0.12);
		border-radius: 50%;
	}

	& > svg {
		margin-bottom: 3px;
	}
}

.overlay {
	display: none;
	position: absolute;
	border-radius: inherit;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	.incompatible > &,
	.wrong > & {
		display: block;
	}

	.incompatible > & {
		visibility: hidden;
		text-decoration: line-through 2px;
	}

	.wrong > & {
		background-color: var(--color-keyboard-key-background-wrong);
	}
}

.hitArea {
	cursor: pointer;
	background: transparent;
	position: absolute;
	top: -$key-hit-slop-vertical;
	left: -$key-hit-slop-horizontal;
	right: -$key-hit-slop-horizontal;
	bottom: -$key-hit-slop-vertical;
}
