@import "../styles/mixins.scss";

.row {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	padding: 0 0 1em;
	flex-wrap: wrap;
	gap: 0em;

	.labelCell {
		flex: 1 1 100%;
		display: flex;
		font-weight: bold;
		text-transform: uppercase;
		opacity: 0.75;
	}

	.valueCell {
		flex: 1 1 100%;
		& * {
			font-size: 1.1em;
		}
	}

	.selectWrapper {
		position: relative;
		width: 100%;
		background-color: var(--color-game-background);
		display: flex;
		color: inherit;
	}

	.selectWrapper::after {
		content: "⌵";
		font-size: 1.5em;
		font-weight: bold;
		top: 3px;
		right: 10px;
		position: absolute;
	}

	.selectField {
		@include basic-reset-select;

		width: 100%;
		display: block;
		padding: 0.25em 0.5em;
		font-size: 1.3em;
		font-weight: bold;
		margin: 0;
		box-sizing: border-box;

		option {
			color: var(--color-keyboard-key-text);
			background-color: var(--color-keyboard-key-background);
		}
	}
}
