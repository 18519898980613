@import "../styles/easings.scss";
@import "../styles/mixins.scss";

.main {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--color-sliding-message-background);
	justify-content: center;
	align-items: center;
	transition: all 0.4s $easing-out-quad;
	transform: scale(1.001);
	border-radius: 4px;
	user-select: none;
	width: 76%;
	height: 130px;
	margin: auto auto;
	padding: 1em 0;
	gap: 1em;

	&.hidden {
		transform: scale(0.9);
		filter: blur(10px);
		opacity: 0;
		pointer-events: none;
	}
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1em;
}

.message {
	font-weight: bold;
	font-size: 20px;
	text-transform: uppercase;
	color: var(--color-sliding-message-text);
	text-align: center;
}

.stars {
	color: white;
}

.shareButton {
	color: var(--color-sliding-message-text);
}
