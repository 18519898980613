@import "../styles/easings.scss";
@import "../styles/mixins.scss";

.version {
	padding: 0.25em 0;
}

.date {
	font-weight: bold;
}

.oldVersionsToggle {
	@include basic-reset-button;

	cursor: pointer;
	font-weight: bold;

	.closeButton {
		vertical-align: text-top;
		transition: all 0.3s $easing-out-quad;

		&.contracted {
			transform: rotate(-90deg);
		}

		&.expanded {
			transform: rotate(0deg);
		}
	}
}

.oldVersions {
	overflow: hidden;
	transition: all 0.3s $easing-out-quad;

	&.oldVersionsShown {
		height: auto;
		transform: translateY(0);
		opacity: 1;
	}

	&.oldVersionsHidden {
		height: 0px;
		transform: translateY(-4px);
		opacity: 0;
	}
}
