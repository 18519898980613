@import-normalize;

@import "@fontsource/source-sans-3/latin-400.css";
@import "@fontsource/source-sans-3/latin-700.css";

@import "./styles/global/colors.scss";

* {
	box-sizing: border-box;
}

:root {
	interpolate-size: allow-keywords;
}

body {
	margin: 0;
	font-family: "Source Sans 3", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--color-page-background);
}

// Scrollbar - for Firefox
html {
	scrollbar-width: thin;
	scrollbar-color: var(--color-scrollbar-foreground) transparent;
}

// Scrollbar - for Chrome
::-webkit-scrollbar {
	width: 14px;
	height: 18px;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 7px;
	background-color: var(--color-scrollbar-foreground);
	box-shadow:
		inset -1px -1px 0px rgba(0, 0, 0, 0.05),
		inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
::-webkit-scrollbar-corner {
	background-color: transparent;
}
