@import "../styles/easings.scss";
@import "../styles/zindexes.scss";

$explosion-duration: 1.1s;

.main {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	user-select: none;
	pointer-events: none;
	z-index: $z-index-celebration;
}
