@import "../styles/easings.scss";

$explosion-duration: 1.1s;
$particle-dimensions: 10px;

.main {
	position: absolute;
	transition: all $easing-in-quad $explosion-duration;
}

.particle {
	position: absolute;
	background-color: currentColor;
	width: $particle-dimensions;
	height: $particle-dimensions;
	top: $particle-dimensions * -0.5;
	left: $particle-dimensions * -0.5;
	border-radius: 2px;
	transition:
		all $easing-out-quart $explosion-duration,
		opacity $easing-in-quad $explosion-duration;
	transform: translate(0px, 0px);
}
